import { ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';
import client from '../../gatsby-plugin-apollo/client';

interface RootElementProps {
  children: ReactNode;
}

export const RootElement = ({ children }: RootElementProps) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
